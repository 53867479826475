
// Vue reactivity
import { computed } from 'vue';

// icons
import { menu, cart, enterOutline, refresh, } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol,
          IonToolbar, IonHeader, IonButton, IonIcon,
          IonItemDivider, IonText, IonCard, IonCardContent, } from '@ionic/vue';
import PageHeader from '@/components/PageHeader.vue';
import Slides from '@/components/Slides.vue';
import DesignPreQuestionModal from '@/components/DesignPreQuestionModal.vue';
import BabeOrderModal from '@/components/BabeOrderModal.vue';

// services
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import config from '@/config';
import { utils } from '@/composables/utils';

export default {
  name: 'HomePage',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol, IonToolbar, IonHeader,
                IonButton, IonIcon, IonItemDivider, IonText, IonCard, IonCardContent,
                PageHeader, Slides, },
  setup() {
    const { openModal, openImageModal, } = utils();
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const openDesignModal = route.params.openDesignModal;

    const newDesign = computed(() => store.state.newDesign);
    const settings = computed(() => store.state.settings);
    const topBanners = computed(() => store.getters.topBanners);
    const loadingData = computed(() => store.state.loadingPublicData);

    const demoPhotoLinks = [];
    for (let i = 1; i <= 12; i++) demoPhotoLinks.push(`https://cms.signals.hk/crystalputeri/demos/demo${i}.jpeg`);

    const openDesignPreQuestionModal = async (clearNewDesign = false) => {
      if (clearNewDesign) {
        store.commit('resetNewDesign');
        localStorage.removeItem(config.newDesignLocalStorageKey);
      }
      if (!clearNewDesign && (newDesign.value.bottle && newDesign.value.bottle.id)) {
        router.replace(newDesign.value.id ? `/designs/${newDesign.value.id}` : '/new-design');
      } else {
        openModal(DesignPreQuestionModal, { designId: null });
      }
    }

    if (openDesignModal == "1") {
      setTimeout(() => openDesignPreQuestionModal(), 100);
    }
    const openBabeOrderModal = async () => (await openModal(BabeOrderModal, {}));

    // return variables & methods to be used in template HTML
    return {
      // icons
      menu, cart, enterOutline, refresh,

      // variables
      settings,
      topBanners, demoPhotoLinks,
      loadingData,
      
      // methods
      t, openImageModal, openBabeOrderModal,
    }
  }
}

